import './Steps.css';
import React, { useEffect } from 'react';
import Home from '../Home/Home';
import ContactPerson from '../ContactPerson/ContactPerson';
import Teams from '../Teams/Teams';
import Participants from '../Participants/Participants';
import Accomodations from '../Accomodation/Accomodations';
import Overview from '../Overview/Overview';
import { useSteps } from '../../../../hooks/useSteps';
import StepsNavigationButtons from '../StepsNavigationButtons/StepsNavigationButtons';
import { useRegistration } from '../../../../context/registrationContext';

interface StepsProps {
    // Define your props here
}

const Steps: React.FC<StepsProps> = ({ }) => {
    const { registration } = useRegistration();
    const { currentStep } = useSteps();

    // useEffect(() => {
    //     console.log('Registration:', registration);
    // }, [registration]);

    const steps: string[] = [
        'Übersicht',
        'Ansprechpartner',
        'Teams',
        'Teilnehmer',
        'Unterkünfte',
        'Übersicht',
    ];

    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return (
                    <div className='bodyContainerSteps'>
                        <Home />
                    </div>
                );
            case 2:
                return <ContactPerson />
            case 3:
                return <Teams />
            case 4:
                return <Participants />
            case 5:
                return <Accomodations />
            case 6:
                return <Overview />
            default:
                return <Home />
        }
    };

    return (
        currentStep !== 1 ?
            <div className='stepsContent'>
                <div className='stepsContainer'>
                    <div className='stepHeadline'>{registration.event.name}</div>
                    <div className='mobileProgressIndicator'>
                        Schritt: {currentStep - 1} von 5
                    </div>
                    <div className='stepName hideOnLaptop'>
                        {steps[currentStep - 1]}
                    </div>
                    <div className='hideOnMobile'>
                        {registration.event.description}
                    </div>
                    <div className='horizontalDivider' />
                    <div className='stepElementsContainer'>
                        <div className='laptopProgressIndicatorContainer'>
                            <div className='laptopProgressIndicator'>
                                <svg id="progressIndicatorSVG" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100%">
                                    <rect x="0" y="0" width="100" height={'100%'} fill='var(--grey-050)' />
                                    <rect x="0" y="0" width="100" height={`calc(10% + (${currentStep - 1} - 1) * 25%)`} fill='var(--tertiary-color)' />
                                    <mask id="mask" x="0" y="0" width="100%" height="100%">
                                        <rect x="0" y="0" width="100%" height="100%" fill="white" />
                                        
                                        <rect x="calc((100% - 10px) / 2)" y="calc((100% - 95%) / 2)" width="10" height="95%" fill="black" />
                                        
                                        <circle cx="calc(100% / 2)" cy="calc(0% + 1 * 14px + 2px)" r="14" fill="black" />
                                        <circle cx="calc(100% / 2)" cy="calc(25% + 8px)" r="14" fill="black" />
                                        <circle cx="calc(100% / 2)" cy="50%" r="14" fill="black" />
                                        <circle cx="calc(100% / 2)" cy="calc(75% - 8px)" r="14" fill="black" />
                                        <circle cx="calc(100% / 2)" cy="calc(100% - 1 * 14px - 2px)" r="14" fill="black" />
                                    </mask>
                                    
                                    <rect x="0" y="0" width="100" height="100%" mask="url(#mask)" fill='var(--primary-color)' />
                                </svg>
                            </div>
                            <div className='laptopProgressIndicatorStepsContainer'>
                                <div className='laptopProgressIndicatorSteps'>
                                    <div>
                                        Ansprechpartner
                                    </div>
                                    <div>
                                        Team
                                    </div>
                                    <div>
                                        Personenanzahl
                                    </div>
                                    <div>
                                        Übernachtungen
                                    </div>
                                    <div>
                                        Übersicht
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='verticalDividerContainer'>
                            <div className='verticalDivider' />
                        </div>
                        <div className='stepElements'>
                            {renderStep()}
                        </div>
                    </div>
                </div>
                <StepsNavigationButtons />
            </div> :
            <>
                {renderStep()}
            </>
    );
};

export default Steps;
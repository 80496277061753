import { useRegistration } from '../../../context/registrationContext';
import { RegistrationType } from '../../../types/registration';
import './AccomodationInputElement.css';
import React from 'react';

interface AccomodationInputElementProps {
    id: number;
    label: string;
    name: string;
    sheetName: string;
    type: string;
    price: number
    placeholder?: string;
    step: keyof RegistrationType;
    index: number;
}

const AccomodationInputElement: React.FC<AccomodationInputElementProps> = ({ id, label, name, sheetName, price, type, placeholder, step, index }) => {
    const { registration, updateRegistration } = useRegistration();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(event.target.value);
        // Create a copy of the accommodations array
        const updatedAccommodations = [...registration.accomodations];
        // Update the amount field in the specific accommodation object
        updatedAccommodations[index] = {
            ...updatedAccommodations[index],
            id: sheetName,
            name: name,
            amount: value, 
            price: price
        };
        // Update the registration state with the new accommodations array
        updateRegistration({
            accomodations: updatedAccommodations
        });
    };

    // Safely access the nested value
    const value = registration.accomodations[index]?.amount ?? '';

    return (
        <div key={id} className='inputElement'>
            <div className='inputElementLabel text-content'>
                {label} *:
            </div>
            <input 
                className='inputElementInput text-content' 
                value={value > 0 ? value : undefined} 
                onChange={handleChange} 
                type={type} 
                placeholder={placeholder ?? ''}
            />
        </div>
    );
};

export default AccomodationInputElement;
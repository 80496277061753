import './Home.css';
import React, { useEffect, useState } from 'react';
import EventCard from '../../../common/eventCard/eventCard';
import { useEXCGruppeAPI } from '../../../../hooks/useApi';
import { EventsReturned, EventType } from '../../../../types/events';

const Home: React.FC = () => {
    const useEXCGruppeAPIHook = useEXCGruppeAPI();
    const [eventsReturned, setEventsReturned] = useState<EventsReturned>();
    const [events, setEvents] = useState<EventType[] | null>(null);

    const loadEvents = async () => {
        const fetchedEvents = await useEXCGruppeAPIHook.getMallecupEvents();
        setEventsReturned(fetchedEvents);
        console.log('Events loading finished!');
    };

    useEffect(() => {
        loadEvents();
    }, []);

    useEffect(() => {
        setEvents(eventsReturned?.events || null);
    }, [eventsReturned]);

    return (
        <div className='homeContent'>
            <div className='homeChildContainer'>
                {events?.map(event => (
                    <EventCard 
                        key={event.id} 
                        id={event.id}
                        name={event.name}
                        sheetName={event.sheetName}
                        description={event.description}
                        fromDate={new Date(event.fromDate)}
                        toDate={new Date(event.toDate)}
                        imageUrl={event.cardImageURL} />
                ))}
            </div>
        </div>
    );
};

export default Home;
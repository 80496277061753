import React, { createContext, useState, ReactNode } from 'react';

interface StepsContextType {
  currentStep: number;
  nextStep: () => void;
  prevStep: () => void;
}

export const StepsContext = createContext<StepsContextType>({
  currentStep: 1,
  nextStep: () => {},
  prevStep: () => {},
});

interface StepsProviderProps {
  children: ReactNode;
}

export const StepsProvider: React.FC<StepsProviderProps> = ({ children }) => {
  const [currentStep, setCurrentStep] = useState<number>(1);

  const nextStep = (): void => {
    if (currentStep < 6) {
      setCurrentStep(prevStep => prevStep + 1);
    }
  };

  const prevStep = (): void => {
    if (currentStep > 1) {
      setCurrentStep(prevStep => Math.max(1, prevStep - 1));
    }
  };

  return (
    <StepsContext.Provider value={{ currentStep, nextStep, prevStep }}>
      {children}
    </StepsContext.Provider>
  );
};
import './App.css';
import { BackgroundImage } from './components/common/backgroundImage/backgroundImage';
import { Body } from './components/layout/Body/Body';
import { Footer } from './components/layout/Footer/Footer';
import { Header } from './components/layout/Header/Header';
import { useEXCGruppeAPI } from './hooks/useApi';
import { useEffect } from 'react';

function App() {
  const useEXCGruppeAPIHook = useEXCGruppeAPI();

  useEffect(() => {
    let message = 'User visited the website';
    message += ` Screen size: ${window.screen.width}x${window.screen.height}`;
    message += ` Platform: ${navigator.platform}`;
    message += ` Language: ${navigator.language}`;
    message += ` User agent: ${navigator.userAgent}`;
    message += ` Referrer: ${document.referrer}`;
    message += ` Path: ${window.location.pathname}`;
    message += ` Hash: ${window.location.hash}`;
    message += ` Search: ${window.location.search}`;

    useEXCGruppeAPIHook.logMessage({ message: message, timestamp: new Date() });
  }, [useEXCGruppeAPIHook]);

  return (
    <div className="App">
      <div className='AppContainer'>
        <Header />
        <Body />
        {/* <Footer /> */}
      </div>
      <BackgroundImage />
    </div>
  );
}

export default App;
import React, { useEffect } from 'react';
import InputElement from '../../../common/inputElement/InputElement';
import TeamInputElement from '../../../common/teamInputElement/TeamInputElement';
import { useRegistration } from '../../../../context/registrationContext';

interface TeamsProps {
    // Define the props for the Teams component here
}

const Teams: React.FC<TeamsProps> = () => {
    const { registration, updateRegistration } = useRegistration();

    useEffect(() => {
        const initialTeams = [
            {id: 'men',
            name: 'Herren',
            price: 100,
            amount: 0},
            {id: 'oldMen',
            name: 'Alte-Herren',
            price: 100,
            amount: 0},
            {id: 'women',
            name: 'Damen',
            price: 100,
            amount: 0},
            {id: 'mixed',
            name: 'Mixed',
            price: 100,
            amount: 0}
        ];

        if (registration.teams.divisions.length === 0) {
            updateRegistration({ ...registration, teams: { name: registration.teams.name, divisions: initialTeams } });
        }
    }, []);

    return (
        <div className='width100 stepContent overviewStepContent'>
            <div className='hideOnMobile stepName'>
                Teams
            </div>
            <InputElement id="name" label="Verein/Firma *&#185;" type="text" step="teams" />
            <div className='laptopWidth40'>
                <TeamInputElement 
                    id="men" 
                    label="Herren *&#178;" 
                    type="number" 
                    placeholder='0' 
                    step="teams"
                    name="Herren" 
                    price={100} 
                    index={0}
                />
            </div>
            <div className='laptopWidth40'>
                <TeamInputElement 
                    id="oldMen" 
                    label="Alte-Herren *&#178;" 
                    type="number" 
                    placeholder='0' 
                    step="teams" 
                    name="Alte-Herren" 
                    price={100} 
                    index={1}
                />
            </div>
            <div className='laptopWidth40'>
                <TeamInputElement 
                    id="women" 
                    label="Damen *&#178;" 
                    type="number" 
                    placeholder='0' 
                    step="teams" 
                    name="Damen" 
                    price={100} 
                    index={2}
                />
            </div>
            <div className='laptopWidth40'>
                <TeamInputElement 
                    id="mixed" 
                    label="Mixed *&#178;" 
                    type="number" 
                    placeholder='0' 
                    step="teams" 
                    name="Mixed" 
                    price={100} 
                    index={3}
                />
            </div>
            <div className='paddingX10 text-content'>
                *&#185; Hier bitte Euren offiziellen Vereinsnamen oder Firmennamen angeben. Um den Mannschaftsnamen Eures Teams kümmern wir uns im späteren Verlauf. Wenn Ihr eine Freundesgruppe seid, lasst dieses Feld frei.
            </div>
            <div className='paddingX10 text-content'>
                *&#178; Bitte gebt an, mit wie vielen Teams Ihr in welcher Spielklasse antreten wollt.
            </div>
        </div>
    );
};

export default Teams;
import './TeamInputElement.css';
import { useRegistration } from '../../../context/registrationContext';
import { RegistrationType } from '../../../types/registration';
import React from 'react';

interface TeamInputElementProps {
    id: string;
    label: string;
    type: string;
    price: number
    placeholder?: string;
    step: keyof RegistrationType;
    name: string;
    index: number;
}

const TeamInputElement: React.FC<TeamInputElementProps> = ({ id, label, price, type, placeholder, step, name, index }) => {
    const { registration, updateRegistration } = useRegistration();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(event.target.value);
        // Create a copy of the teams array
        const updatedTeamsDivisions = [...registration.teams.divisions];
        // Update the amount field in the specific team object
        updatedTeamsDivisions[index] = {
            ...updatedTeamsDivisions[index],
            id: id,
            amount: value,
            name: name,
            price: price
        };
        // Update the registration state with the new teams array
        updateRegistration({
            teams: {
                ...registration.teams,
                divisions: updatedTeamsDivisions
            }
        });
    };

    // Safely access the nested value
    const value = registration.teams.divisions[index]?.amount || '';

    return (
        <div key={id} className='inputElement'>
            <div className='inputElementLabel text-content'>
                {label}:
            </div>
            <input 
                className='inputElementInput text-content' 
                value={value}
                onChange={handleChange} 
                type={type} 
                placeholder={placeholder ?? ''}
            />
        </div>
    );
};

export default TeamInputElement;
import './eventCard.css';
import { useSteps } from '../../../hooks/useSteps';
import React from 'react';
import { useRegistration } from '../../../context/registrationContext';

interface Props {
    id: number;
    name: string;
    sheetName: string;
    description: string;
    fromDate: Date;
    toDate: Date;
    imageUrl: string;
}

const EventCard: React.FC<Props> = ({ id, name, sheetName, description, fromDate, toDate, imageUrl }) => {
    const { nextStep } = useSteps();
    const { updateRegistration } = useRegistration();

    const handleOnClick = async () => {
        updateRegistration({
            event: {
                id,
                name,
                sheetName,
                description,
                fromDate,
                toDate
            },
            contactPerson: {
                firstname: '',
                lastname: '',
                email: '',
                phone: '',
                street: '',
                streetNumber: '',
                zip: '',
                city: ''
            },
            teams: {
                name: '',
                divisions: []
            },
            participants: {
                amount: 0
            },
            accomodations: [],
            overview: {
                furtherInformation: '',
                coupon: '',
                AGBChecked: false
            }
        });
        nextStep();
    };

    return (
        <div className='eventCardContainer' onClick={handleOnClick}>
            <div className='eventImage' style={{ backgroundImage: `url(${imageUrl})` }}>
            </div>
        </div>
    );
};

export default EventCard;
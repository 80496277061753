import './StepsNavigationButtons.css';
import { useSteps } from '../../../../hooks/useSteps';
import React from 'react';
import { useRegistration } from '../../../../context/registrationContext';
import { useEXCGruppeAPI } from '../../../../hooks/useApi';
import { setupRegistrationData } from '../../../../utils/helper';
import { addInvoicenumberData } from '../../../../types/invoicenumber';

const StepsNavigationButtons = () => {
    // Add your component logic here
    const { nextStep, prevStep, currentStep } = useSteps();
    const { registration } = useRegistration();
    const useEXCGruppeAPIHook = useEXCGruppeAPI();

    const handleClick = async() => {
        if (currentStep === 6) {
            if (registration.overview.AGBChecked) {
                const fetchedInvoiceNumber = await useEXCGruppeAPIHook.getMaxInvoiceNumberForEvent(registration.event.id);
                const registrationData = setupRegistrationData(fetchedInvoiceNumber['invoiceNumber'] === 0 ? 1 : fetchedInvoiceNumber['invoiceNumber'], registration);
                if (await useEXCGruppeAPIHook.addMallecupRegistration(registrationData)) {
                    const invoiceNumberData: addInvoicenumberData = { year: registration.event.fromDate.getFullYear(), eventId: registration.event.id, invoiceNumber: (fetchedInvoiceNumber['invoiceNumber'] === 0 ? 1 : fetchedInvoiceNumber['invoiceNumber']) + 11, status: 1};
                    if (await useEXCGruppeAPIHook.addInvoiceNumber(invoiceNumberData)) {
                        console.log('Invoice number added!');
                    }
                    console.log('Registration successful!');
                    useEXCGruppeAPIHook.logMessage({ message: `User entered following information ${JSON.stringify(registration)} in the step ${currentStep} and was redirected to the "Anmeldebestätigung" page`, timestamp: new Date() });
                    window.location.href = 'https://mallecup.com/anmeldebestaetigung/';
                    return;
                }
            }
            console.log('Registration failed!');
        } else {
            useEXCGruppeAPIHook.logMessage({ message: `User entered following information ${JSON.stringify(registration)} in the step ${currentStep}`, timestamp: new Date() });
            nextStep();
        }
    };

    return (
        <div className='bodyContainerButtons'>
            <div className='buttonContainer'>
                <button className='bodyContainerButton' onClick={prevStep}>Zurück</button>
                <button className='bodyContainerButton continueButton' onClick={handleClick}>
                    {currentStep === 6 ? 'Buchen' : 'Weiter'}
                </button>
            </div>
        </div>
    );
};

export default StepsNavigationButtons;
import './Accomodations.css';
import React, { useEffect, useState } from 'react';
import { useEXCGruppeAPI } from '../../../../hooks/useApi';
import { AccomodationsReturned, AccomodationType } from '../../../../types/accomodations';
import { formatPrice, getWeekday } from '../../../../utils/helper';
import { useRegistration } from '../../../../context/registrationContext';
import AccomodationInputElement from '../../../common/accomodationInputElement/AccomodationInputElement';

const Accommodations: React.FC = () => {
    const useEXCGruppeAPIHook = useEXCGruppeAPI();
    const [accomodationsReturned, setAccomodationsReturned] = useState<AccomodationsReturned>();
    const [accomodations, setAccomodations] = useState<AccomodationType[] | null>(null);
    const { registration, updateRegistration } = useRegistration();

    const [openAccomodations, setOpenAccomodations] = useState<number>(0);

    const loadAccomodations = async () => {
        const fetchedAccomodations = await useEXCGruppeAPIHook.getMallecupAccomdationsByEvent(registration.event.id);
        setAccomodationsReturned(fetchedAccomodations);
        console.log('Accomodations loading finished!');
    };

    useEffect(() => {
        loadAccomodations();
    }, []);

    useEffect(() => {
        setAccomodations(accomodationsReturned?.accomodations || null);
        if (accomodationsReturned && accomodationsReturned.total !== registration.accomodations.length) {
            const initialAccomodations = accomodationsReturned?.accomodations?.map(accomodation => ({
                id: accomodation.sheetName,
                name: accomodation.name,
                price: accomodation.price,
                amount: 0
            }));
            updateRegistration({ ...registration, accomodations: initialAccomodations });
        }
    }, [accomodationsReturned]);

    useEffect(() => {
        let totalAccommodations: number = 0;
        registration.accomodations?.forEach(accomodation => {
            totalAccommodations += +accomodation.amount;
        });

        setOpenAccomodations(registration.participants.amount - totalAccommodations);
    }, [accomodations, registration]);
    
    return (
        <div className='width100 accomodationContainer stepContent'>
            <div className='hideOnMobile stepName'>
                Übernachtungen
            </div>
            {accomodations?.map((accomodation, index) => (
                <AccomodationInputElement 
                    key={accomodation.id} 
                    id={accomodation.id}
                    name={accomodation.name}
                    sheetName={accomodation.sheetName}
                    price={accomodation.price}
                    label={`${accomodation.name} / ${formatPrice(accomodation.price)} €`} 
                    type='number' 
                    placeholder='0' 
                    step="accomodations"
                    index={index}
                />
            ))}
            <div className='leftAccomodations text-content price-content'>
                Noch zu buchende Übernachtungen: {openAccomodations} 
            </div>
            <div className='paddingTop20 text-content'>
                * Bitte wähle die Personenanzahl aus, die diese Kategorie buchen will.
            </div>
        </div>
    );
};

export default Accommodations;
import './backgroundImage.css';
import React, { useEffect, useRef, useState } from 'react';

interface BackgroundImageProps {
  // Define your props here
}

export const BackgroundImage: React.FC<BackgroundImageProps> = ({ }) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [style, setStyle] = useState({});
  const [windowIsSmaller, setWindowIsSmaller] = useState(false);

  useEffect(() => {
    // Function to update the dimensions of the image based on the window size
    const updateDimensions = () => {
      if (imgRef.current) {
        const { naturalWidth, naturalHeight } = imgRef.current;
        const windowRatio = window.innerWidth / window.innerHeight;
        const imageRatio = naturalWidth / naturalHeight;
        
        let newStyle = {};
        if (imageRatio > windowRatio) {
          // Image is wider than the window
          newStyle = {
            width: 'auto',
            height: '100%',
            maxWidth: `none`,
            maxHeight: `${window.innerHeight}px`,
          };
          setWindowIsSmaller(true);
        } else {
          // Image is taller than the window
          newStyle = {
            width: '100%',
            height: 'auto',
            maxWidth: `${window.innerWidth}px`,
            maxHeight: 'none',
          };
          setWindowIsSmaller(false);
        }
        setStyle(newStyle);
      }
    };

    // Selecting a random backgroundImage from the images array
    const images = [
      require('../../../assets/images/BackgroundImage1.jpg'),
      require('../../../assets/images/BackgroundImage2.jpg'),
      require('../../../assets/images/BackgroundImage3.jpg'),
      require('../../../assets/images/BackgroundImage4.jpg'),
    ];
    
    const randomIndex = Math.floor(Math.random() * images.length);
    const selectedImage = images[randomIndex];

    if (imgRef.current) {
      imgRef.current.src = selectedImage;
    }

    const handleResize = () => {
      updateDimensions();
    };

    // Event listener to handle window resize
    window.addEventListener('resize', handleResize);
    updateDimensions();

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='backgroundImage'>
      <div className='backgroundImageContainer' style={windowIsSmaller ? { overflow: 'hidden', height: '100%' } : { overflow: 'hidden', width: '100%' }}>
        <img
          ref={imgRef}
          alt='backgroundImage'
          style={{
            ...style,
            objectFit: 'contain',
          }}
        />
      </div>
    </div>
  );
};
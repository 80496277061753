import './paymentOverviewElement.css';
import { formatPrice } from '../../../utils/helper';
import React from 'react';
import { useRegistration } from '../../../context/registrationContext';

interface PaymentOverviewElementProps {
    header: string;
    positions: { id: string, amount: number, name: string, price: number }[];
    cheapestAccomodation? : { id: string, amount: number, name: string, price: number };
}

const PaymentOverviewElement: React.FC<PaymentOverviewElementProps> = ({ header, positions, cheapestAccomodation }) => {
    const { registration } = useRegistration();

    return (
        <div className='paymentOverviewElementContainer'>
            <div className='paymentOverviewElementHeader text-content'>
                {header}:
            </div>
            <div className='paymentOverviewElementElements'>
                {positions.map((position, index) => {
                    if (position.amount > 0) {
                        return (
                            <div key={index} className='paymentOverviewElementElementCotainer'>
                                <div className='paymentOverviewElementElementDescription text-content'>
                                    {position.amount + 'x ' + position.name}
                                </div>
                                <div className='paymentOverviewElementElementPrice price-content'>
                                    {formatPrice(position.amount * position.price)} €
                                </div>
                            </div>
                        );
                    }
                })}
            </div>
            {cheapestAccomodation && new Date() <= new Date('2025-01-01') && registration.teams.divisions.reduce((acc, position) => acc + position.amount, 0) > 0 && <div className='paymentOverviewElementElementCotainer'>
                <div className='paymentOverviewElementElementDescription text-content'>
                    {registration.teams.divisions.reduce((acc, position) => acc + position.amount, 0) + 'x Freiplatz ' + cheapestAccomodation.id}
                </div>
                <div className='paymentOverviewElementElementPrice price-content'>
                    {formatPrice((registration.teams.divisions.reduce((acc, position) => acc + position.amount, 0) * cheapestAccomodation.price) * -1)} €
                </div>
            </div>}
        </div>
    );
};

export default PaymentOverviewElement;
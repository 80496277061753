import './Header.css';
import React from 'react';
import { ReactComponent as Logo } from '../../../assets/svgs/EXCEventsLogo.svg';

interface HeaderProps {
  // Define your props here
}

export const Header: React.FC<HeaderProps> = ({  }) => {
  return (
    <div className='Header' id="Header">
        <div className='HeaderLogo' />
        <h1 className='HeaderHeadline'>Anmeldung 2025</h1>
      </div>
  );
};
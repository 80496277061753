import React, { createContext, ReactNode, useContext, useState } from 'react';
import { RegistrationType } from '../types/registration';

type PathImpl<T, K extends keyof T> =
  K extends string
  ? T[K] extends Record<string, any>
    ? T[K] extends ArrayLike<any>
      ? K | `${K}.${PathImpl<T[K], Exclude<keyof T[K], keyof any[]>>}`
      : K | `${K}.${PathImpl<T[K], keyof T[K]>}`
    : K
  : never;

type Path<T> = PathImpl<T, keyof T> | keyof T;

type PathValue<T, P extends Path<T>> =
  P extends `${infer K}.${infer Rest}`
  ? K extends keyof T
    ? Rest extends Path<T[K]>
      ? PathValue<T[K], Rest>
      : never
    : never
  : P extends keyof T
    ? T[P]
    : never;

type NestedUpdate<T> = Partial<{
  [K in Path<T>]: PathValue<T, K>;
}>;

interface RegistrationContextProps {
  registration: RegistrationType;
  updateRegistration: (updates: NestedUpdate<RegistrationType>) => void;
}

const RegistrationContext = createContext<RegistrationContextProps | undefined>(undefined);

interface RegistrationProviderProps {
  children: ReactNode;
}

export const RegistrationProvider: React.FC<RegistrationProviderProps> = ({ children }) => {
  const [registration, setRegistration] = useState<RegistrationType>({
    event: {
      id: 0,
      name: '',
      sheetName: '',
      description: '',
      fromDate: new Date(),
      toDate: new Date(),
    },
    contactPerson: {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      street: '',
      streetNumber: '',
      zip: '',
      city: '',
    },
    teams: {
      name: '',
      divisions: [],
    },
    participants: {
      amount: 0,
    },
    accomodations: [],
    overview: {
      furtherInformation: '',
      coupon: '',
      AGBChecked: false,
    },
  });

  const updateRegistration = (updates: NestedUpdate<RegistrationType>) => {
    setRegistration((prev) => {
      const newRegistration = { ...prev };
      (Object.entries(updates) as [Path<RegistrationType>, any][]).forEach(([path, value]) => {
        const keys = path.split('.') as (keyof RegistrationType)[];
        let current: any = newRegistration;
        const lastKey = keys.pop()!;
        
        for (const key of keys) {
          if (!(key in current)) {
            current[key] = {};
          }
          current = current[key];
        }
        
        current[lastKey] = value;
      });
      return newRegistration;
    });
  };

  return (
    <RegistrationContext.Provider value={{ registration, updateRegistration }}>
      {children}
    </RegistrationContext.Provider>
  );
};

export const useRegistration = () => {
  const context = useContext(RegistrationContext);
  if (context === undefined) {
    throw new Error('useRegistration must be used within a RegistrationProvider');
  }
  return context;
};
import './ContactPerson.css';
import InputElement from '../../../common/inputElement/InputElement';
import React from 'react';

const ContactPerson = () => {
    return (
        <div className='width100 stepContent'>
            <div className='hideOnMobile stepName'>
                Ansprechpartner
            </div>
            <div className='laptopRow width100'>
                <InputElement id="firstname" label='Vorname' type='text' step="contactPerson" />
                <InputElement id="lastname" label='Nachname' type='text' step="contactPerson" />
            </div>
            <div className='laptopWidth80'>
                <InputElement id="email" label='E-Mail' type='email' step="contactPerson" />
            </div>
            <div className='laptopWidth80'>
                <InputElement id="phone" label='Handynummer' type='text' step="contactPerson" />
            </div>
            <div className='stepElementsGroup'>
                <InputElement id="street" label='Straße' type='text' step="contactPerson" />
                <div className='mobileThirdWith'>
                    <InputElement id="streetNumber" label='Hausnummer' type='text' step="contactPerson" />
                </div>
            </div>
            <div className='stepElementsGroup'>
                <div className='mobileThirdWith'>
                    <InputElement id="zip" label='Postleitzahl' type='number' step="contactPerson" />

                </div>
                <InputElement id="city" label='Stadt' type='text' step="contactPerson" />
            </div>
        </div>
    );
};

export default ContactPerson;
import './Body.css';
import React from 'react';
import { ReactComponent as Logo } from '../../../assets/svgs/EXCEventsLogo.svg';
import { ReactComponent as LogoBackground } from '../../../assets/svgs/LogoBackground.svg';
import { StepsProvider } from '../../../context/stepsContext';
import Steps from './Steps/Steps';
import { RegistrationProvider } from '../../../context/registrationContext';

interface BodyProps {
  // Define your props here
}

export const Body: React.FC<BodyProps> = ({  }) => {

  function calculateMaxBodyHeight(): number {
    const totalHeight = window.innerHeight;
    const header = document.getElementById('Header');
    // const footer = document.getElementById('Footer');

    if (!header /*|| !footer*/) {
      // console.error('Header or footer element not found');
      console.error('Header element not found');
      return 0;
    }

    const headerHeight = header.getBoundingClientRect().height;
    // const footerHeight = footer.getBoundingClientRect().height;

    const maxBodyHeight = totalHeight - headerHeight /*- footerHeight*/;
    return Math.max(maxBodyHeight, 0);
  };

  function updateBodyHeight(): void {
    const body = document.getElementById('body');
    if (!body) {
      console.error('Body element not found');
      return;
    }

    const maxBodyHeight = calculateMaxBodyHeight();
    body.style.maxHeight = `${maxBodyHeight}px`;
  };

  updateBodyHeight();

  window.addEventListener('resize', () => {
    requestAnimationFrame(updateBodyHeight);
  });

  return (
    <StepsProvider>
      <RegistrationProvider>
        <div className='body' id='body'>
          <div className='bodyHeader'>
            <LogoBackground className='bodyHeaderLogoBackground' />
            <div className='bodyHeaderLogo' />
          </div>
          <div className='bodyContainer'>
            <Steps />
          </div>
        </div>
      </RegistrationProvider>
    </StepsProvider>
  );
};
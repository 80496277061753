import React from 'react';
import InputElement from '../../../common/inputElement/InputElement';

interface ParticipantsProps {
    // Define your component props here
}

const Participants: React.FC<ParticipantsProps> = () => {
    // Implement your component logic here

    return (
        <div className='width100 stepContent'>
            <div className='hideOnMobile stepName'>
                Teilnehmer
            </div>
            <InputElement id="amount" label="Anzahl Personen *" type="number" placeholder='0' step="participants" />
            <div className='paddingX10 text-content'>
                * Mit wie vielen Personen werdet Ihr insgesamt anreisen/ teilnehmen?
            </div>
        </div>
    );
};

export default Participants;